import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Icon, Avatar } from "antd";
import { get } from "lodash";
import moment from "moment";
import cx from "classnames";

import { makeDate, formatURL, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";

export default function OrderItem({ updatedAt, crowdfundingProject, paymentData }) {
	const { theme } = useThemeContext();
	const themeStyle = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
	});

	const image = get(crowdfundingProject, "images[0]", "") || get(crowdfundingProject, "mainImage", "");
	const title = get(crowdfundingProject, "title", "");
	const buildingSlug = get(crowdfundingProject, "path", "");
	const amount = get(paymentData, "amount", "");

	return (
		<div className={"row"}>
			<div className={"mb-3 mb-md-0 col-12 col-md-4"}>
				{!!image && (
					<Avatar
						shape={"square"}
						size={300}
						src={image}
						style={{ objectFit: "cover" }}
					/>
				)}
			</div>
			<div className={"col-12 col-md-8"} style={themeStyle}>
				{!!title && (
					<Link
						className={cx("d-flex order-list__title", { "mb-3": !!updatedAt })}
						to={formatURL(buildingSlug)}
					>
						<Icon type="compass" className={"order-list__icon"} style={{ marginRight: 8 }} />
						{title}
					</Link>
				)}
				{!!updatedAt && (
					<Link
						to={`/calendar/${moment(updatedAt).format("YYYY-MM-DD")}`}
						className={cx("d-flex order-list__title", { "mb-3": !!amount })}
					>
						<Icon type="calendar" className={"order-list__icon"} style={{ marginRight: 8 }} />
						{makeDate(updatedAt)}
					</Link>
				)}
				{!!amount && (
					<div className={"d-flex order-list__title"}>
						<Icon type="credit-card" className={"order-list__icon"} style={{ marginRight: 8 }} />
						{amount} руб.
					</div>
				)}
			</div>
		</div>
	);
}

OrderItem.propTypes = {
	updatedAt: PropTypes.string,
	crowdfundingProject: PropTypes.object,
	paymentData: PropTypes.object,
};

OrderItem.defaultProps = {
	updatedAt: "",
	crowdfundingProject: {},
	paymentData: {},
};
