import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Icon, Popover, Tag } from "antd";
import { get } from "lodash";
import cx from "classnames";
import moment from "moment";

import { useThemeContext } from "../../Layout/ThemeContext";
import { makeDate, formatURL, removeEmptyKeys } from "../../../helpers";

export default function TrebItem({ state, trebNames, updatedAt, trebType, trebCategory, crowdfundingProject, paymentData }) {
	const { theme } = useThemeContext();
	const themeStyle = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
	});

	const types = {
		dead: "Записка об упокоении",
		life: "Записка о здравии",
	};

	const orderStatus = {
		"created": <Tag color={"red"}>Не оплачено</Tag>,
		"confirmed": <Tag color={"green"}>Оплачено</Tag>,
		"approved": <Tag color={"gold"}>Подтверждено</Tag>,
		"default": "",
	};

	const status = orderStatus[state];
	const invoiceId = get(paymentData, "invoiceId", "");

	const type = types[trebType];

	function splitNames(names = []) {
		if (names instanceof Array) {
			if (names.length > 5) {
				const leftBlock = names.slice(0, 5);
				const rightBlock = names.slice(5);

				return (
					<div className={"row"}>
						<div className={"col-lg-6 col-xs-12"}>
							{leftBlock.map((name, idx) => <p style={removeEmptyKeys({ color: theme.color.text })} key={idx}>{name}</p>)}
						</div>
						<div className={"col-lg-6 col-xs-12"}>
							{rightBlock.map((name, idx) => <p style={removeEmptyKeys({ color: theme.color.text })} key={idx}>{name}</p>)}
						</div>
					</div>
				);
			}

			return names.map((name, idx) => <p style={removeEmptyKeys({ color: theme.color.text })} key={idx}>{name}</p>);
		}

		return null;
	}

	const place = get(crowdfundingProject, "place", "");
	const amount = get(paymentData, "amount", "");
	const buildingSlug = get(crowdfundingProject, "path", "");

	const popoverContentByType = {
		"Заказная записка": "Заказная записка (заказная) — разовое поминовение о здравии или об упокоении с выниманием частицы из просфоры на проскомидии во время Божественной литургии, главного церковного богослужения.",
		"Сорокоуст": "Сорокоуст — особое молитвенное поминовение о здравии и об упокоении, совершаемое в Церкви ежедневно в течение 40 дней на Божественной литургии, главном церковном богослужении.",
		"Годовая": "Годовое поминовение (годовая) — поминовение о здравии и об упокоении, совершаемое в Церкви ежедневно в течение года. Имена записываются в поминальный синодик, после чего в монастыре в течение указанного срока за каждым богослужением молятся об этих людях.",
		"Панихида": "Панихида — это особое краткое богослужение, во время которого мы поминаем умерших и просим у Бога прощения их грехов. Панихиду можно заказывать не только в день смерти и дни особого поминовения усопших, но и каждый день.",
	};

	function getPopoverContent(trebCategory) {
		return (
			<div>
				{popoverContentByType[trebCategory]}
			</div>
		);
	}

	return (
		<>
			<div
				className={"mb-3 row"}
				style={themeStyle}
			>
				<div className={"col-12 mb-3 d-flex flex-xs-column flex-md-row flex-lg-row flex-xl-row justify-content-between"}>
					{type && (
						<h4 style={themeStyle}>
							{type}
						</h4>
					)}
					<div>
						{status}<Tag>№ {invoiceId}</Tag>
					</div>

				</div>
				{!!trebCategory && (
					<div className={cx("col-12 col-md-6 col-lg-3 order-list__block", { "mb-3": !!place })}>
						<div className={"d-flex order-list__title order-list__title-treb order-list__category"}>
							<Popover
								content={getPopoverContent(trebCategory)}
								title={trebCategory}
								trigger="click"
								overlayClassName={`order-list__popover order-list__popover_${theme.color.key}`}
							>
								<Icon type="tag" className={"order-list__icon"} style={{ marginRight: 8 }} />
								{trebCategory}
							</Popover>
						</div>
					</div>
				)}
				{!!place && (
					<div className={cx("col-12 col-md-6 col-lg-4 order-list__block", { "mb-3": !!updatedAt })}>
						<Link
							className={"d-flex order-list__title order-list__title-treb"}
							to={formatURL(buildingSlug)}
						>
							<Icon type="compass" className={"order-list__icon"} style={{ marginRight: 8 }} />
							{place}
						</Link>
					</div>
				)}
				{!!updatedAt && (
					<div className={cx("col-12 col-md-6 col-lg-3 order-list__block", { "mb-3": !!amount })}>
						<Link
							to={`/calendar/${moment(updatedAt).format("YYYY-MM-DD")}`}
							className={"d-flex order-list__title order-list__title-treb"}
						>
							<Icon type="calendar" className={"order-list__icon"} style={{ marginRight: 8 }} />
							{makeDate(updatedAt)}
						</Link>
					</div>
				)}
				{!!amount && (
					<div className={"col-12 col-md-6 col-lg-2 order-list__block"}>
						<div className={"d-flex order-list__title order-list__title-treb"}>
							<Icon type="credit-card" className={"order-list__icon"} style={{ marginRight: 8 }} />
							{amount} руб.
						</div>
					</div>
				)}
			</div>
			{trebNames && !!trebNames.length && splitNames(trebNames)}
		</>
	);
}

TrebItem.propTypes = {
	state: PropTypes.string,
	trebNames: PropTypes.array,
	updatedAt: PropTypes.string,
	trebType: PropTypes.string,
	trebCategory: PropTypes.string,
	crowdfundingProject: PropTypes.object,
	paymentData: PropTypes.object,
};

TrebItem.defaultProps = {
	state: "",
	trebNames: [],
	updatedAt: "",
	trebType: "",
	trebCategory: "",
	crowdfundingProject: {},
	paymentData: {},
};
