import React, { useState, useEffect } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { Button, Icon } from "antd";
import PropTypes from "prop-types";

ReactS3Uploader.propTypes = {};
export default function InputS3Uploader({ src, onDelImg, onLoadImg }) {
	const [img, setImg] = useState(src);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		setImg(src);
	}, [src]);

	function onFinish(result) {
		const img = result.signedUrl.split("?")[0];
		setImg(img);
		onLoadImg(img);
	}

	function onProgress(state) {
		setUploading(!state);
	}

	function handleDelete() {
		setImg(null);
		onDelImg();
	}

	const uploadOptions = Object.assign(
		{},
		{
			signingUrl: "https://serafim.help/api/s3/sign",
			signingUrlMethod: "GET",
			accept: "*/*",
			uploadRequestHeaders: { "x-amz-acl": "public-read" },
			signingUrlWithCredentials: true,
			signingUrlQueryParams: { uploadType: "avatar" },
			autoUpload: true,
			multiple: false,
			onFinish,
			onProgress,
		},
	);

	return (
		<div className={"uploader"} style={{ width: "100%" }}>
			{
				img &&
				<div className={"uploader__file mb-3"}
					style={{
						overflow: "hidden",
					}}>
					<img
						src={img}
						alt={img}
						style={{
							height: 300,
							width: "100%",
							objectFit: "cover",
						}}
					/>
				</div>
			}
			<div className={"uploader__input"}>
				<div
					style={{
						position: "relative",
						marginBottom: "1rem",
					}}
				>
					{!img ? (<>
						<Icon type={"user-add"} style={{ fontSize: 150,
							marginBottom: 21 }} />

						<Button
							variant={"contained"}
							color={"primary"}
							style={{
								width: "100%",
								marginBottom: "1rem",
							}}
							disabled={uploading}
						>
							<Icon type={"upload"} style={{ marginRight: 15 }}/>
							<label
								htmlFor={"img"}
								style={{
									position: "absolute",
									width: "100%",
									height: "100%",
									left: 0,
									top: 0,
									display: "block",
									cursor: "pointer",
								}}
							/>
							{uploading ? "Загрузка" : "Загрузить изображение "}
						</Button>
					</>) : null}

					{img &&
					<Button
						variant={"contained"}
						color={"default"}
						style={{
							width: "100%",
						}}
						onClick={handleDelete}
					>
						Удалить изображение
					</Button>}
					{uploading && "Загружается"}
				</div>
				<ReactS3Uploader
					id={"img"}
					style={{ display: "none" }}
					{...uploadOptions}
				/>
			</div>
		</div>
	);
}

InputS3Uploader.propTypes = {
	src: PropTypes.string,
	onDelImg: PropTypes.func,
	onLoadImg: PropTypes.func,
};

InputS3Uploader.defaultProps = {
	src: "",
	onDelImg: () => Function,
	onLoadImg: () => Function,
};
