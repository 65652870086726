import React, { createElement, useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { List, Button, notification, Modal, Tabs } from "antd";
import { get, has } from "lodash";
import { useToggle } from "@umijs/hooks";

import { UPDATE_CURRENT_TREB, UPDATE_CURRENT_ORDER } from "../../../queries/queries.graphql";

import OrderItem from "../OrderItem";
import TrebItem from "../TrebItem";
import CurrentOrderModal from "../CurrentOrder";
import ApproveOrder from "../ApproveOrder";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./OrderList.scss";
import { removeEmptyKeys } from "../../../helpers";

export default function OrderList({ items }) {
	const { TabPane } = Tabs;
	const { confirm } = Modal;
	const { Item } = List;
	const { theme } = useThemeContext();

	const { state: showCurrentOrder, toggle: setShowCurrentOrder } = useToggle();
	const { state: showApproveOrder, toggle: setShowApproveOrder } = useToggle();

	const [approveData, setApproveData] = useState({});
	const [tabKey, setTabKey] = useState(get(items, "[0].type", ""));

	useEffect(() => {
		if (has(approveData, "paymentData")) {
			setShowApproveOrder();
		}
	}, [approveData]);

	const orderMap = {
		"donate": OrderItem,
		"treb": TrebItem,
		"crowdfunding": OrderItem, // TODO: Need to create new template
		"tour": OrderItem, // TODO: Need to create new template
		"default": null,
	};

	const [updateCurrentTreb] = useMutation(UPDATE_CURRENT_TREB);
	const [updateCurrentOrder] = useMutation(UPDATE_CURRENT_ORDER);

	// TODO: Need to implementation the split ytest below

	// const variants = ["Подписаться", "Оформить подписку", "Повторять регулярно"];

	// function handleSubscribeBtnClick(title) {
	// 	if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
	// 		// eslint-disable-next-line
	// 		ym(
	// 			56949043,
	// 			"reachGoal",
	// 			"subscribe-btn",
	// 			{
	// 				"А/Б Тесты": {
	// 					"Текст на кнопке подписки":	title,
	// 				},
	// 			},
	// 		);
	// 	}
	// }

	function onClickApprove(id, anonymous, email, paymentData, crowdfundingProject, trebCategory, trebType, trebNames, reload, params) {
		setApproveData({
			id,
			anonymous,
			email,
			paymentData,
			crowdfundingProject,
			trebCategory,
			trebType,
			trebNames,
			reload,
			params,
		});
	}

	function pay(paymentData = null, callback = () => Function) {
		if (typeof cp !== "undefined" && paymentData) {
			// eslint-disable-next-line no-undef
			const widget = new cp.CloudPayments({ language: "ru" });

			widget.charge(
				Object.assign({}, paymentData, {
					skin: "mini",
				}),
				() => {
					notification.success({
						message: "Платёж Выполнен",
					});
				},
				() => {
					notification.error({
						message: "Платёж отменён",
					});
				},
			);
		} else {
			notification.error({
				message: "Ошибка загрузки платёжного виджета",
			});
			callback(false);
		}
	}

	function repeat({ paymentData, crowdfundingProject, anonymous, email, trebCategory, trebNames, trebType, reload }) {
		const firstName = get(paymentData, "data.firstName", null);
		const phone = get(paymentData, "data.phone", null);
		const delivery = get(paymentData, "delivery", null);
		const amount = +get(paymentData, "amount", 0);
		const goals = get(paymentData, "goals", []);
		const crowdfundingProjectId = get(crowdfundingProject, "id", "");

		const variables = {
			firstName,
			email,
			phone,
			delivery,
			anonymous,
			trebCategory,
			trebNames,
			trebType,
			amount,
			goals,
			crowdfundingProjectId,
			paymentType: "natural",
			rewards: ["154"],
			title: "",
		};

		updateCurrentOrder({ variables })
			.then(updatedOrder => {
				const paymentData = get(updatedOrder, "data.updateCurrentOrder.order.paymentData", null);

				reload()
					.then(() => {
						notification.success({
							message: "Заказ успешно создан",
						});

						pay(paymentData);
					})
					.catch(error => {
						notification.success({
							message: "Ошибка создания заказа",
						});

						console.error(error);
					});
			})
			.catch(error => {
				notification.error({
					message: "Ошибка данных формы",
				});

				console.error(error);
			});
	}

	function confirmation(id, trebType, reload) {
		const trebTypeMap = {
			dead: {
				title: "Вы собираетесь изменить тип требы с записки об упокоении на записку о здравии.",
				type: "life",
			},
			life: {
				title: "Вы собираетесь изменить тип требы с записки о здравии на записку об упокоении.",
				type: "dead",
			},
		};
		const content = get(trebTypeMap, `[${trebType}].title`, "");

		trebType = get(trebTypeMap, `[${trebType}].type`, "dead");

		confirm({
			title: "Подтвердите свои действия",
			centered: true,
			content,
			onOk() {
				return new Promise(resolve => {
					updateCurrentTreb({
						variables: {
							id,
							trebType,
						},
					})
						.then(updatedTreb => {
							if (!get(updatedTreb, "data.updateTreb.result", true)) {
								notification.error({
									message: "Операция невозможна, для изменения параметров требы обратитесь в службу поддержки",
									duration: 10,
								});

								resolve();
							} else {
								reload()
									.then(() => {
										notification.success({
											message: "Список обновлён",
										});

										resolve();
									})
									.catch(error => {
										notification.error({
											message: "Ошибка обновления списка",
										});

										resolve();

										console.error(error);
									});
							}
						})
						.catch(error => {
							notification.error({
								message: "Ошибка действия",
							});

							resolve();

							console.error(error);
						});
				});
			},
			onCancel() {},
			okText: "Подтвердить",
			cancelText: "Отмена",
		});
	}

	function makeActions({ id, anonymous, email, paymentData, crowdfundingProject, trebCategory, trebType, trebNames, reload, ...params }) {
		const kind = get(crowdfundingProject, "kind", "");

		function changeTreb() {
			return kind === "treb" ? (
				<Button
					icon={"edit"}
					onClick={() => confirmation(id, trebType, reload)}
				>
				Изменить тип требы
				</Button>
			) : null;
		}
		function repeatOrder() {
			return (
				<Button
					icon={"reload"}
					onClick={() => repeat(
						{
							paymentData,
							crowdfundingProject,
							anonymous,
							email,
							trebCategory,
							trebType,
							trebNames,
							reload,
						},
					)}
				>
					Повторить
				</Button>
			);
		}

		return {
			"created": [
				<Button
					key={"list-loadmore-edit"}
					icon={"wallet"}
					onClick={setShowCurrentOrder}
				>
					Оплатить
				</Button>,
				changeTreb(),
			],
			"confirmed": [
				kind !== "treb" && !anonymous && (
					<Button
						key={"approve"}
						icon={"check-circle"}
						onClick={() =>
							onClickApprove(
								id, anonymous, email, paymentData, crowdfundingProject, trebCategory, trebType, trebNames, reload, params,
							)
						}
					>
						Получить подарок
					</Button>
				),
				repeatOrder(),
				changeTreb(),
			],
			"approved": [
				repeatOrder(),
			],
			"default": [
				repeatOrder(),
				changeTreb(),
			],
		};
	}

	function reloadTab(tab) {
		setTabKey(tab);
		const tabParams = items.filter(item => get(item, "type", "") === tab);

		if (get(tabParams, "[0].reload", false)) {
			tabParams[0].reload();
		}
	}

	function onCloseCurrentOrder() {
		reloadTab(tabKey);
		setShowCurrentOrder();
	}

	function onCloseApproveOrder() {
		reloadTab(tabKey);
		setShowApproveOrder();
		setApproveData({});
		notification.info({
			message: "Список обнволен",
		});
	}

	function getActions(reload, state, params) {
		const actions = makeActions({
			...params,
			reload,
		})[state || "default"];

		if (actions) {
			return actions.filter(action => action);
		}

		return null;
	}

	return (
		<>
			<Tabs type={"card"} defaultActiveKey={"0"} onTabClick={key => reloadTab(key)}>
				{
					items.map(({ title = "", items = [], type = "", emptyText = "", reload = () => Function }) => {
						return (
							<TabPane
								key={`${type}`}
								tab={<span style={{ color: tabKey !== type && theme.color.text }}>{title}</span>}
							>
								<List
									loading={false}
									itemLayout={"vertical"}
									loadMore={true}
									dataSource={items}
									style={{
										width: "100%",
										height: "100%",
									}}
									locale={{ emptyText }}
									renderItem={({ state, ...params }, idx) => {
										return (
											<Item
												key={`${type}-item-${idx}`}
												actions={getActions(reload, state, params)
												}
												className={"list-item"}
												style={removeEmptyKeys({ color: theme.color.text })}
											>
												{createElement(orderMap[type] || orderMap.default, { state,
													...params })}
											</Item>
										);
									}}
								/>
							</TabPane>
						);
					})
				}
			</Tabs>
			{showCurrentOrder && <CurrentOrderModal visible={showCurrentOrder} onClose={onCloseCurrentOrder} /> }
			{showApproveOrder && <ApproveOrder data={approveData} visible={showApproveOrder} onClose={onCloseApproveOrder} /> }
		</>
	);
}

OrderList.propTypes = {
	items: PropTypes.array,
};

OrderList.defaultProps = {
	items: [],
};
