import React, { useEffect, useContext } from "react";
import { Form, Input, Button, notification, Icon } from "antd";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useMutation } from "@apollo/react-hooks";
import IMask from "imask";

import { AuthContext } from "../../../widgets";
import InputS3Uploader from "../../S3Uploader";
import { UPDATE_CURRENT_USER } from "../../../queries/queries.graphql";
import { removeEmptyKeys, removePreloader } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";

import "../profile.scss";

function EditProfile({ form, user, setEditMode }) {
	const { theme } = useThemeContext();
	const { getToken, updateClient } = useContext(AuthContext);

	const [updateUser, { loading: loadingUser }] = useMutation(UPDATE_CURRENT_USER);

	const { getFieldDecorator, setFieldsValue } = form;

	useEffect(() => {
		// FIXME: use ref instead query selector
		// eslint-disable-next-line new-cap
		IMask(document.querySelector("[data-id=phone]"), { mask: "+{7}(000)000-00-00" });
		removePreloader();
	}, []);

	function handleSubmit() {
		form.validateFields(async (err, values) => {
			// eslint-disable-next-line no-use-before-define
			const variables = getFilledValues(values);

			if (!err) {
				updateUser({
					variables,
				})
					.then(() => {
						getToken(
							data => {
								const token = get(data, "token", "");
								localStorage.setItem("serafim_token", token);
								updateClient();
								notification.success({
									message: "Данные успешно обновлены",
								});
								setEditMode(false);
							},
							error => {
								console.error(error);
								notification.error({
									message: "Ошибка соединения",
								});
							},
						);
					})
					.catch(error => {
						notification.error({
							message: "Ошибка сохранения",
						});
						console.error(error);
					});
			} else {
				notification.error({
					message: "Поля формы заполнены некорректно",
				});
			}
		});
	}

	function getFilledValues(values) {
		for (let key in values) {
			if (!values[key]) {
				values[key] = null;
			}
		}

		return values;
	}

	const avatar = get(user, "avatar", "");
	/* const firstName = get(user, "first_name", "");
	const lastName = get(user, "last_name", "");
	const email = get(user, "email", ""); */
	const phone = get(user, "phone", "");

	return (
		<div className={"row profile-edit"}>
			<div className={"col-lg-2"} />
			<div className={"col-xs-12 col-md-4 col-lg-4"} style={{ textAlign: "center" }}>
				<InputS3Uploader
					label=""
					src={avatar}
					onDelImg={() => setFieldsValue({ avatar: null })}
					onLoadImg={img => setFieldsValue({ avatar: img })} />
			</div>
			<div className="col-xs-12 col-lg-4">
				<Form onSubmit={handleSubmit}>
					<Form.Item >
						{getFieldDecorator("avatar", {
							initialValue: avatar,
						})(<Input style={{ display: "none" }} />)}
					</Form.Item>
					{/* <Form.Item>
						{getFieldDecorator("firstName", {
							initialValue: firstName,
							rules: [
								{
									message: "Введите имя!",
								},
							],
						})(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={"Имя"} disabled />)}
					</Form.Item> */}
					{/* <Form.Item>
						{getFieldDecorator("lastName", {
							initialValue: lastName,
							rules: [
								{
									message: "Введите фамилию!",
								},
							],
						})(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={"Фамилия"} disabled />)}
					</Form.Item> */}
					{/* <Form.Item>
						{getFieldDecorator("email", {
							initialValue: email,
							rules: [
								{
									message: "Ваш E-mail!",
								},
							],
						})(<Input prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={"E-mail"} type={"email"} disabled />)}
					</Form.Item> */}
					<Form.Item>
						{getFieldDecorator("phone", {
							initialValue: phone,
							rules: [
								{
									message: "Ваш телефон!",
								},
							],
						})(<Input
							prefix={<Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder={"Телефон"}
							type={"phone"}
							data-id={"phone"}
						/>)}
					</Form.Item>
					<div className={"d-flex justify-content-between"}>
						<Button
							block
							type="primary"
							onClick={() => setEditMode(false)}
							style={removeEmptyKeys({
								backgroundColor: theme.color.text,
								color: theme.color.body,
								width: "45%",
							})}>
							Отмена
						</Button>
						<Button
							block
							type="primary"
							onClick={handleSubmit}
							loading={loadingUser}
							disabled={loadingUser}
							style={removeEmptyKeys({
								backgroundColor: theme.color.text,
								color: theme.color.body,
								width: "45%",
							})}>
							Сохранить
						</Button>
					</div>
				</Form>
			</div>
			<div className={"col-lg-2"} />
		</div>
	);
}

export default Form.create({ name: "profileEdit" })(EditProfile);

EditProfile.propTypes = {
	form: PropTypes.object,
	user: PropTypes.object,
	setEditMode: PropTypes.func,
};

// TODO: use ant design form structure

EditProfile.defaultProps = {
	form: {},
	user: {},
	setEditMode: () => Function,
};
