import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";

import { ORDER_LIST } from "../../queries/queries.graphql";
import Pages from "../../components/Pages";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { removePreloader } from "../../helpers";
import { AuthContext } from "../../widgets";
import Profile from "../../components/Profile";
import OrderList from "../../components/Crowdfunding/OrderList";

export default function ProfilePage() {
	const { user } = useContext(AuthContext);
	const isLogin = get(user, "isLogin", false);

	const { loading: trebsLoading, error: trebsError, data: trebsData, refetch: trebsRefetch } = useQuery(
		ORDER_LIST,
		{
			variables: {
				kind: "treb",
			},
			fetchPolicy: "no-cache",
		},
	);

	const { loading: donatesLoading, error: donatesError, data: donatesData, refetch: donatesRefetch } = useQuery(
		ORDER_LIST,
		{
			variables: {
				kind: "donate",
			},
			fetchPolicy: "no-cache",
		},
	);

	// const { loading: toursLoading, error: toursError, data: toursData, refetch: toursRefetch } = useQuery(
	// 	ORDER_LIST,
	// 	{
	// 		variables: {
	// 			kind: "tour",
	// 		},
	// 		fetchPolicy: "no-cache",
	// 	},
	// );

	// const { loading: crowdfundingsLoading, error: crowdfundingsError, data: crowdfundingsData, refetch: crowdfundingsRefetch } = useQuery(
	// 	ORDER_LIST,
	// 	{
	// 		variables: {
	// 			kind: "crowdfunding",
	// 		},
	// 		fetchPolicy: "no-cache",
	// 	},
	// );

	useEffect(() => {
		removePreloader(!trebsLoading && !donatesLoading);
	}, [trebsLoading, donatesLoading]);

	useEffect(() => {
		if (trebsError || donatesError) {
			console.error(
				"Some error",
				{
					trebsError,
					donatesError,
				},
			);
		}
	}, [trebsError, donatesError]);

	const trebs = get(trebsData, "userOrders", []);
	const donates = get(donatesData, "userOrders", []);
	// const tours = get(toursData, "userOrders", []);
	// const crowdfunding = get(crowdfundingsData, "userOrders", []);

	const orders = [
		{
			title: "Мои пожертвования",
			items: donates,
			reload: donatesRefetch,
			emptyText: "Тут будут отображаться ваши пожертвования",
			type: "donate",
		},
		{
			title: "Мои требы",
			items: trebs,
			reload: trebsRefetch,
			emptyText: "Тут будут отображаться ваши требы.",
			type: "treb",
		},
		// {
		// 	title: "Мои туры",
		// 	items: tours,
		// 	reload: toursRefetch,
		// 	emptyText: "Тут будут отображаться ваши туры.",
		// 	type: "tour",
		// },
		// {
		// 	title: "Мои crowdfundings", // FIXME: Need to rename this title
		// 	items: crowdfunding,
		// 	reload: crowdfundingsRefetch,
		// 	emptyText: "Тут будут отображаться ваши crowdfundings.",
		// 	type: "crowdfunding",
		// },
	];

	return (
		<Pages>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs currentLabel={isLogin ? "Личный кабинет" : "Мои действия"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"profile-page"} mount={get(user, "isLogin", false)}>
				<div className={"container"}>
					<TextBlock title={"Личный кабинет"}>
						<Profile />
					</TextBlock>
				</div>
			</ContentBlock>
			<ContentBlock key={"tabs"} >
				<div className="container">
					<TextBlock title={"Мои действия"}>
						<OrderList items={orders} />
					</TextBlock>
				</div>
			</ContentBlock>
		</Pages>
	);
}

ProfilePage.propTypes = {
	data: PropTypes.object,
};
