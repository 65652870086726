import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { get } from "lodash";
import { Form, Input, notification, Button, Checkbox, Modal } from "antd";
import PropTypes from "prop-types";

import { TextBlock } from "../../Blocks";
import { AuthContext } from "../../../widgets";
import { UPDATE_ORDER } from "../../../queries/queries.graphql";
import {
	donate,
	cancel,
	messageForAnonymous,
	anonymDonate,
	formMessages,
	noData,
} from "../../../constants";
import {
	getPhoneNumberValidationRules,
	getEmailValidationRules,
	getFirstNameValidationRules,
	getAddressValidationRules,
} from "../../../utils";
import { useThemeContext } from "../../Layout/ThemeContext";

import "../CurrentOrder/CurrentOrder.scss";

export default function ApproveOrder({ data, visible, onClose }) {
	const { user } = useContext(AuthContext);
	const { theme } = useThemeContext();
	const [updateOrder, { loading }] = useMutation(UPDATE_ORDER);

	const anonymous = get(data, "anonymous", false);
	const title = get(data, "crowdfundingProject.title", "");
	const description = get(data, "crowdfundingProject.description", "");
	const firstNAme = get(user, "first_name", "-");
	const email = get(user, "email", "-");
	const phone = get(user, "phone", "-");
	const imageSrc = get(data, "crowdfundingProject.mainImage", "");

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={"Подтвердите Ваши действия"}
				centered
				visible={visible}
				onCancel={onClose}
				width={1300}
				footer={[
					<div key={"submit-group"} className={"container-fluid px-0"}>
						<div className={"row"}>
							<div className={"col-12 h4"}>
							Подтвердите свои данные
							</div>
						</div>
						<WrappedCurrentOrderForm
							currentOrder={data}
							user={user}
							loading={loading}
							updateOrder={updateOrder}
							visibl={visible}
							onClose={onClose}
						/>
					</div>,
				]}
			>
				<TextBlock title={data ? `Данные о пожертвовании № ${data.id || ""}` : noData}>
					<div className={"container-fluid"}>
						<div className={"row p-3"} style={{ border: "1px solid gray" }}>
							<div className={"col-md-7"}>
								<h5>{title}</h5>
								<p>{description}</p>
							</div>
							<div className={"col-md-3"}>
								{anonymous ? (
									<div>Пожертвование сделано анонимно</div>
								) : (
									<div>
										<div>
											<span>Имя пользователя: </span>
											{firstNAme}
										</div>
										<div>
											<span>E-mail: </span>
											{email}
										</div>
										<div>
											<span>Телефон: </span>
											{phone}
										</div>
									</div>
								)}
							</div>
							<div className={"col-md-2"}>
								{imageSrc && <img async alt={"logo"} src={imageSrc} height={100} width={100} />}
							</div>
						</div>
					</div>
				</TextBlock>
			</Modal>
		</>
	);
}

ApproveOrder.propTypes = {
	data: PropTypes.object,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
};

ApproveOrder.defaultProps = {
	data: {},
	visible: false,
	onClose: () => Function,
};

function ApproveOrderForm({ form, currentOrder, user, updateOrder, onClose, loading }) {
	const {
		getFieldDecorator,
		getFieldValue,
		getFieldsValue,
		validateFields,
	} = form;

	const [anonymous, setAnonymous] = useState(get(currentOrder, "anonymous", false));

	const firstName = get(user, "first_name", "");
	const email = get(user, "email", "");
	const phone = get(user, "phone", "");
	const delivery = get(currentOrder, "delivery", "");

	async function handleSubmit(event) {
		event.preventDefault();
		validateFields(async error => {
			if (!error) {
				updateOrder({
					variables: {
						orderId: +get(currentOrder, "id"),
						amount: get(currentOrder, "paymentData.amount", 0),
						goals: get(currentOrder, "goals", []),
						paymentType: "natural",
						crowdfundingProjectId: +get(currentOrder, "crowdfundingProject.id"),
						rewards: get(currentOrder, "rewards", ["154"]),
						title: "",
						trebCategory: null,
						trebType: null,
						trebNames: null,
						...getFieldsValue(),
						phone: getFieldsValue().phone ? `+7${getFieldsValue().phone}` : "",
					},
				})
					.then(onClose)
					.catch(error => {
						notification.error({
							message: "Ошибка сохранения",
						});
						console.error(error);
					});
			} else {
				notification.error({
					message: "Некорректные данные формы",
				});
			}
		});
	}

	const isAnonymous = getFieldValue("anonymous");

	return (
		<Form layout={"vertical"} onSubmit={handleSubmit}>
			<style>
				{`
					.ant-input-group-addon {
						color: white;
					}
				`}
			</style>
			<Form.Item>
				{getFieldDecorator("anonymous", {
					valuePropName: "checked",
					initialValue: !!anonymous,
				})(<Checkbox onChange={() => setAnonymous(!anonymous)}>{anonymDonate}</Checkbox>)}
			</Form.Item>
			<Form.Item>{messageForAnonymous}</Form.Item>
			<Form.Item label={formMessages.firstName.label}>
				{getFieldDecorator("firstName", {
					initialValue: !anonymous ? firstName : "",
					rules: getFirstNameValidationRules(!anonymous),
				})(<Input placeholder={formMessages.firstName.placeholder} disabled={isAnonymous} />)}
			</Form.Item>
			<Form.Item label={formMessages.email.label}>
				{getFieldDecorator("email", {
					initialValue: !anonymous ? email : "",
					rules: getEmailValidationRules(!anonymous),
				})(<Input placeholder={formMessages.email.placeholder} type={"email"} disabled={isAnonymous} />)}
			</Form.Item>
			<Form.Item label={formMessages.phone.label}>
				{getFieldDecorator("phone", {
					initialValue: !anonymous ? phone : "",
					rules: getPhoneNumberValidationRules(!anonymous),
				})(<Input placeholder={formMessages.phone.placeholder} disabled={isAnonymous} addonBefore={"+7"}/>)}
			</Form.Item>
			<Form.Item label={formMessages.address.label}>
				{getFieldDecorator("delivery", {
					initialValue: !anonymous ? delivery : "",
					rules: getAddressValidationRules(!anonymous),
				})(<Input placeholder={formMessages.address.placeholder} disabled={isAnonymous} />)}
			</Form.Item>
			<Form.Item>
				<Button type="primary" disabled={loading} onClick={onClose}>
					{cancel}
				</Button>
				<Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
					{donate}
				</Button>
			</Form.Item>
		</Form>
	);
}

ApproveOrderForm.propTypes = {
	form: PropTypes.object,
	currentOrder: PropTypes.object,
	setLoading: PropTypes.func,
	updateOrder: PropTypes.func,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	user: PropTypes.object,
};

ApproveOrderForm.defaultTypes = {
	form: {},
	currentOrder: {},
	setLoading: () => Function,
	updateOrder: () => Function,
	loading: false,
	onClose: () => Function,
	user: {},
};

const WrappedCurrentOrderForm = Form.create({ name: "CurrentOrderForm" })(ApproveOrderForm);
