import React, { useEffect, useState, useContext } from "react";
import { get } from "lodash";
import { notification, Button, Modal } from "antd";
import PropTypes from "prop-types";

import { withCurrentOrder } from "../../../containers/withCrowdfunding";
import { TextBlock } from "../../Blocks";
import { AuthContext } from "../../../widgets";
import { noData } from "../../../constants";
import { useThemeContext } from "../../Layout/ThemeContext";

import "./CurrentOrder.scss";

function CurrentOrder(props) {
	const currentOrder = get(props, "currentOrder.currentOrder", {});
	const visible = get(props, "visible", false);
	const onClose = get(props, "onClose", () => Function);
	const { theme } = useThemeContext();
	const { user } = useContext(AuthContext);

	const [anonymous, setAnonymous] = useState(get(currentOrder, "anonymous", false));
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAnonymous(get(currentOrder, "anonymous", false));
	}, [currentOrder]);

	const delivery = get(currentOrder, "delivery", "");
	const amount = get(currentOrder, "amount", 0);
	const goals = get(currentOrder, "goals", []);
	const crowdfundingProject = get(currentOrder, "crowdfundingProject", []);
	const rewards = get(currentOrder, "rewards", ["154"]);
	const firstName = get(user, "first_name", "-");
	const email = get(user, "email", "-");
	const phone = get(user, "phone", "-");
	const imageSrc = get(currentOrder, "crowdfundingProject.mainImage", "");
	const title = get(currentOrder, "crowdfundingProject.title", "");
	const description = get(currentOrder, "crowdfundingProject.description", "");

	async function handleUpdateStatus(event) {
		event.preventDefault();

		const currentData = {
			firstName,
			email,
			phone,
			delivery,
			anonymous,
			amount: +amount,
			goals,
			paymentType: "natural",
			crowdfundingProjectId: crowdfundingProject.id,
			rewards,
			title: "",
		};

		setLoading(true);

		props.updateCurrentOrder({ variables: currentData })
			.then(res => {
				const paymentData = get(res, "data.updateCurrentOrder.order.paymentData", null);
				// const state = get(updatedOrder, "data.updateCurrentOrder.order.state", null);

				// eslint-disable-next-line no-use-before-define
				pay(paymentData);
			})
			.catch(error => {
				setLoading(false);
				console.error(error);
				notification.error({
					message: "Ошика формы",
				});
			});
	}

	function pay(paymentData = null, callback = () => {}) {
		if (typeof cp !== "undefined" && paymentData) {
			// eslint-disable-next-line no-undef
			const widget = new cp.CloudPayments({ language: "ru" });

			widget.charge(
				Object.assign({}, paymentData, {
					skin: "mini",
				}),
				() => {
					notification.success({
						message: "Платёж Выполнен",
					});
					setLoading(false);
					onClose();
				},
				() => {
					notification.error({
						message: "Платёж отменён",
					});
					setLoading(false);
				},
			);
		} else {
			notification.error({
				message: "Ошибка загрузки платёжного виджета",
			});
			callback(false);
		}
	}

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={"Подтвердите Ваши действия"}
				centered
				visible={visible}
				onCancel={() => onClose(false)}
				width={1300}
				footer={[
					<div key={"submit-group"} className={"container-fluid px-0"}>
						<div className={"row"}>
							<div className={"col-6"}>
								<Button
									block
									type="primary"
									onClick={() => onClose(false)}
									disabled={loading}
								>
								Отменить
								</Button>
							</div>
							<div className={"col-6"}>
								<Button
									block
									type="primary"
									onClick={handleUpdateStatus}
									loading={loading}
									disabled={loading}
								>
								Оплатить
								</Button>
							</div>
						</div>
					</div>,
				]}
			>
				<TextBlock title={currentOrder ? `Данные о пожертвовании № ${currentOrder.id || ""}` : noData}>
					<div className={"container-fluid"}>
						<div className={"row p-3"} style={{ border: "1px solid gray" }}>
							<div className={"col-md-7"}>
								<h5>{title}</h5>
								<p>{description}</p>
							</div>
							<div className={"col-md-3"}>
								{anonymous ? (
									<div>Пожертвование сделано анонимно</div>
								) : (
									<div>
										<div>
											<span>Имя пользователя: </span>
											{firstName}
										</div>
										<div>
											<span>E-mail: </span>
											{email}
										</div>
										<div>
											<span>Телефон: </span>
											{phone}
										</div>
									</div>
								)}
							</div>
							<div className={"col-md-2"}>
								{imageSrc && <img async alt={"logo"} src={imageSrc} height={100} width={100} />}
							</div>
						</div>
					</div>
				</TextBlock>
			</Modal>
		</>
	);
}

CurrentOrder.propTypes = {
	currentOrder: PropTypes.object,
	updateCurrentOrder: PropTypes.func,
};

export default withCurrentOrder(CurrentOrder);
