import React, { useContext } from "react";
import { useToggle } from "@umijs/hooks";

import EditProfile from "./Edit";
import ShowProfile from "./Show";
import { AuthContext } from "../../widgets";

export default function Profile() {
	const { user } = useContext(AuthContext);

	const { state: editMode, toggle: setEditMode } = useToggle();

	return (
		<>
			{
				editMode ?
					<EditProfile user={user} setEditMode={setEditMode} />
					:
					<ShowProfile user={user} setEditMode={setEditMode} />
			}
		</>
	);
}
